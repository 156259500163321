import useMessages from '@/hooks/use-messages'
import dynamic from 'next/dynamic'

import Cell from './cell'
import {TextTooltip} from './text-tooltip'

const AccessTable = dynamic(() => import('./access-table'), {ssr: false})

export default function AccessCard() {
  const m = useMessages('AccessCard')
  return (
    <div className='rounded bg-white px-3 pt-3 shadow'>
      <h4 className='mb-1 font-medium'>
        <TextTooltip tooltip={m('Tooltip')}>{m('Title')}</TextTooltip>
      </h4>
      <div className='prose'>
        <Cell>
          <AccessTable />
        </Cell>
      </div>
    </div>
  )
}
