import {
  arrow,
  autoUpdate,
  FloatingArrow,
  FloatingPortal,
  offset,
  shift,
  useFloating
} from '@floating-ui/react'
import {useAtom, useAtomValue} from 'jotai'
import {usePlausible} from 'next-plausible'
import {useRef} from 'react'
import {FaArrowRight} from 'react-icons/fa'

import {
  StepKeys,
  Steps,
  walkthroughOpenAtom,
  walkthroughStepAtom,
  walkthroughTotalStepsAtom
} from '@/atoms/walkthrough'
import useMessages from '@/hooks/use-messages'
import useIsMobile from '@/hooks/use-is-mobile'

import Button from './button'

import {PlausibleEvents} from '@/types'

export default function WalkthroughStep({
  children,
  step
}: React.PropsWithChildren<{
  step: StepKeys
}>) {
  const stepNumber = Steps[step]
  const [walkthroughIsOpen, setWalkthroughIsOpen] = useAtom(walkthroughOpenAtom)
  const [currentStep, setStep] = useAtom(walkthroughStepAtom)
  const totalSteps = useAtomValue(walkthroughTotalStepsAtom)
  const arrowRef = useRef(null)
  const nextRef = useRef(null)
  const isOpen = walkthroughIsOpen && currentStep === stepNumber
  const m = useMessages('Walkthrough')
  const isMobile = useIsMobile()
  const plausible = usePlausible<PlausibleEvents>()

  const floating = useFloating({
    placement: isMobile ? 'top' : 'right',
    open: isOpen,
    onOpenChange: setWalkthroughIsOpen,
    middleware: [offset(10), shift(), arrow({element: arrowRef})],
    whileElementsMounted: autoUpdate
  })

  function onClose() {
    plausible('walkthroughFinished', {props: {step: stepNumber}})
    setWalkthroughIsOpen(false)
  }

  return (
    <>
      <div ref={floating.refs.setReference}>{children}</div>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={floating.refs.setFloating}
            className='z-40'
            style={{
              position: floating.strategy,
              top: floating.y ?? 0,
              left: floating.x ?? 0,
              width: 'max-content'
            }}
          >
            <div className='flex max-w-xs flex-col gap-4 rounded border border-slate-900/10 bg-white p-4 shadow'>
              <div>
                <h4 className='flex justify-between pb-3 text-lg font-semibold'>
                  {m('Step', {step: `${stepNumber}`})}
                </h4>
                <div dangerouslySetInnerHTML={{__html: m(step)}} />
              </div>
              <div className='flex gap-2'>
                <Button
                  color='primary'
                  ref={nextRef}
                  size='sm'
                  onClick={() => setStep((s) => s + 1)}
                  style={{
                    display: stepNumber === totalSteps ? 'none' : 'inherit'
                  }}
                >
                  {m('NextStep')} <FaArrowRight />
                </Button>
                <Button size='sm' onClick={onClose}>
                  {m('Close')}
                </Button>
              </div>
            </div>
            <FloatingArrow
              className='z-50 fill-white'
              ref={arrowRef}
              context={floating.context}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
