import {Combobox} from '@headlessui/react'
import {FaCompass, FaMapMarkerAlt, FaSearch, FaStar} from 'react-icons/fa'

import {StepKeys} from '@/atoms/walkthrough'
import useGeocoder, {GeocoderOption} from '@/hooks/use-geocoder'
import useMessages from '@/hooks/use-messages'

import WalkthroughStep from './walkthrough-step'

import type {LabeledCoord} from '@/types'

function getDisplayValue(o: LabeledCoord | string) {
  return typeof o === 'string' ? o : o.label ?? ''
}

function selectOnFocus(e: React.FocusEvent<HTMLInputElement>) {
  e.currentTarget.select()
}

const optionStyles = `bg-white pt-3 pb-2.5 px-3 first:rounded-t last:rounded-b cursor-pointer w-full border-b-2 hover:outline hover:outline-blue-500/50 flex items-center group`

export default function Geocoder({
  hexColor,
  label,
  onChange,
  placeholder,
  step,
  value
}: {
  hexColor: string
  label: string
  onChange: (f: LabeledCoord) => void
  placeholder: string
  step: StepKeys
  value: LabeledCoord
}) {
  const g = useGeocoder(value, onChange)
  const m = useMessages()

  return (
    <div className='relative'>
      <label className='font-medium text-white'>{label}</label>
      <Combobox onChange={g.onChange} value={g.value}>
        <div className='relative mt-1 w-full'>
          <Combobox.Button className='absolute inset-y-0 left-0 flex cursor-text items-center pl-3'>
            <FaMapMarkerAlt style={{color: hexColor}} />
          </Combobox.Button>
          <WalkthroughStep step={step}>
            <Combobox.Input
              className='form-input w-full overflow-ellipsis rounded px-10 py-2'
              displayValue={getDisplayValue}
              onBlur={g.onBlur}
              onChange={g.onChangeQuery}
              onFocus={selectOnFocus}
              placeholder={placeholder}
            />
          </WalkthroughStep>
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-3'>
            {g.isGeocoding === 0 ? (
              <FaSearch className=' text-blue-500' />
            ) : (
              <FaCompass className='animate-spin' />
            )}
          </Combobox.Button>
        </div>
        <div className='relative z-10'>
          <Combobox.Options className='absolute top-2 w-full shadow-lg'>
            {g.options.length === 0 ? (
              g.isGeocoding ? (
                <Combobox.Option
                  disabled
                  className={optionStyles}
                  value='No options found'
                >
                  {m('Geocoding.Searching')}
                </Combobox.Option>
              ) : g.query.length === 0 ? (
                <></>
              ) : (
                <Combobox.Option
                  disabled
                  className={optionStyles}
                  value='No options found'
                >
                  {m('Geocoding.NotFound')}
                </Combobox.Option>
              )
            ) : (
              g.options.map((o, index) => (
                <Option key={index} hexColor={hexColor} option={o} />
              ))
            )}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  )
}

function Option({
  option,
  hexColor
}: {
  option: GeocoderOption
  hexColor: string
}) {
  return (
    <Combobox.Option className={optionStyles} value={option}>
      <span
        className='mr-3 text-slate-200 group-hover:text-[--hex-color]'
        style={
          {
            '--hex-color': hexColor
          } as any
        }
      >
        {option.isPoi ? <FaStar /> : <FaMapMarkerAlt />}
      </span>
      <span>{option.label}</span>
    </Combobox.Option>
  )
}
