import {color as d3Color, RGBColor} from 'd3-color'
import {atom, useAtomValue} from 'jotai'

import {configAtom} from './config'

function createColor(hexString: string): RGBColor {
  const color = d3Color(hexString)
  if (color == null) throw new Error(`Invalid color ${hexString}`)
  return color.rgb()
}

export const colorsAtom = atom<{
  markerColors: {
    start: RGBColor
    end: RGBColor
  }
  networkColors: RGBColor[]
}>((get) => {
  const config = get(configAtom)
  const networkColors: RGBColor[] = []
  for (const colorString of config.networkColors) {
    networkColors.push(createColor(colorString))
  }
  return {
    markerColors: {
      start: createColor(config.markerColors.start),
      end: createColor(config.markerColors.end)
    },
    networkColors: networkColors
  }
})

export function useMarkerColors() {
  const colors = useAtomValue(colorsAtom)
  return colors.markerColors
}

export function useNetworkColors() {
  const colors = useAtomValue(colorsAtom)
  return colors.networkColors
}

export function useDarkPrimary() {
  const colors = useNetworkColors()
  return colors[0].darker(4)
}
