import {atom} from 'jotai'

export const walkthroughOpenAtom = atom<boolean>(true)
export const walkthroughStepAtom = atom<number>(-1)
export const walkthroughStepsAtom = atom<string[]>([])
export const walkthroughTotalStepsAtom = atom<number>(6)

export const Steps = {
  Start: 1,
  End: 2,
  TimeOfDay: 3,
  TimeCutoff: 6,
  Accessibility: Infinity,
  Routes: 5,
  SelectTransitModes: 4,
  MapClickControl: Infinity,
  Legend: Infinity
} as const

export type StepKeys = keyof typeof Steps
