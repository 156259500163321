import {useAtomValue, useSetAtom} from 'jotai'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import Image from 'next/image'
import {FaInfoCircle} from 'react-icons/fa'
import Balancer from 'react-wrap-balancer'

import {configAtom} from '@/atoms/config'
import {setUserDataAtom} from '@/atoms/user'
import useMessages from '@/hooks/use-messages'
import useIsMobile from '@/hooks/use-is-mobile'

import AlternateTimeSelector from './alternate-time-selector'
import Dock from './dock'
import GeocodeSearch from './geocode-search'
import WelcomeDialog from './info'
import TimeCutoff from './time-cutoff'
import ScenarioCards from './scenario-cards'
import AccessCard from './access-card'
import WalkthroughStep from './walkthrough-step'
import Button from './button'
import LocaleSelector from './locale'
import Cell from './cell'
import Loader from './loader'
import SelectTransitModes from './select-transit-modes'

const LOGO = 'https://d2f1n6ed3ipuic.cloudfront.net/conveyal-128x128.png'

function MapLoader() {
  return (
    <div className='absolute left-[50%] top-[50%] -ml-6 -mt-6'>
      <Loader />
    </div>
  )
}

const RideOnAlert = dynamic(() => import('./ride-on-alert'), {ssr: false})
const Map = dynamic(() => import('./map'), {
  ssr: false,
  loading: MapLoader
})

const ogImgUrl = 'https://betterbus.taui.conveyal.com/og.jpg'

export default function Application() {
  const config = useAtomValue(configAtom)
  const m = useMessages()
  const isMobile = useIsMobile()

  return (
    <>
      <Head>
        <link rel='shortcut icon' href={LOGO} type='image/x-icon' />
        <title>{m('Title')}</title>
        <meta name='description' content={m('Description')} />
        <meta
          name='keywords'
          content='bus,network,dc,visionary,2023,redesign,washington,'
        />
        <meta name='author' content='Conveyal' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta property='og:title' content={m('Title')} />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={m('Description')} />
        <meta property='og:image' content={ogImgUrl} />
        <meta property='og:url' content='https://betterbus.taui.conveyal.com' />
        <meta property='twitter:image' content={ogImgUrl} />
      </Head>

      {config.showWelcomeDialog && <WelcomeDialog />}

      <Dock>
        <DockHeading />

        {isMobile && <MobileLink />}

        <GeocodeSearch />

        {config.showPercentileSelector && (
          <WalkthroughStep step='TimeOfDay'>
            <AlternateTimeSelector />
          </WalkthroughStep>
        )}

        {config.showSelectTransitModes && (
          <WalkthroughStep step='SelectTransitModes'>
            <SelectTransitModes />
          </WalkthroughStep>
        )}

        {config.showAccessCard && (
          <WalkthroughStep step='Accessibility'>
            <AccessCard />
          </WalkthroughStep>
        )}

        <Cell fallback={null}>
          <RideOnAlert />
        </Cell>

        <WalkthroughStep step='Routes'>
          <ScenarioCards />
        </WalkthroughStep>

        {!isMobile && (
          <WalkthroughStep step='TimeCutoff'>
            <TimeCutoff />
          </WalkthroughStep>
        )}

        {config.showLink && <Attribution />}
      </Dock>

      <div className='fixed left-96 right-0 hidden h-screen sm:block'>
        {isMobile ? <MapLoader /> : <Map />}
      </div>
    </>
  )
}

function DockHeading() {
  const config = useAtomValue(configAtom)
  const setUser = useSetAtom(setUserDataAtom)
  const m = useMessages()

  return (
    <div className='flex flex-col gap-3'>
      <Balancer className='text-xl font-medium text-white'>
        {m('Title')}
      </Balancer>
      <div className='flex items-stretch gap-4'>
        <LocaleSelector color='primary' grow size='sm' />
        {config.showWelcomeDialog && (
          <Button
            color='primary'
            onClick={() => setUser({showInfo: true})}
            grow
            size='sm'
            title={m('WelcomeDialog.ShowButton')}
          >
            {m('WelcomeDialog.ShowButton')}
            <FaInfoCircle />
          </Button>
        )}
      </div>
    </div>
  )
}

function Attribution() {
  return (
    <div className='flex justify-center py-6 font-medium text-white'>
      <span>site made by</span>
      <Image
        alt='Logo'
        className='w-10 px-2'
        width={40}
        height={40}
        src={LOGO}
      />
      <a href='https://www.conveyal.com'>conveyal</a>
    </div>
  )
}

function MobileLink() {
  const m = useMessages()
  return (
    <div
      className='rounded bg-white p-2 px-3 sm:hidden'
      dangerouslySetInnerHTML={{__html: m('MobileOnlyAlert')}}
    />
  )
}
