import {fromPixel} from './coordinate-to-point'

import type {BBox} from '@turf/helpers/dist/js/lib/geojson'
import type {Scenario} from '@/types'

export default function getScenarioBbox(s: Scenario): BBox {
  const sw = fromPixel([s.west, s.north + s.height], s.zoom)
  const ne = fromPixel([s.west + s.width, s.north], s.zoom)
  return [sw[0], sw[1], ne[0], ne[1]]
}
