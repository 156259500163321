import {Dialog} from '@headlessui/react'
import {useAtomValue, useSetAtom} from 'jotai'
import {FaArrowRight} from 'react-icons/fa'

import {setUserDataAtom, userAtom} from '@/atoms/user'
import useStartWalkthrough from '@/hooks/use-start-walkthrough'
import useMessages from '@/hooks/use-messages'

import Button from './button'
import LocaleSelector from './locale'

export default function WelcomeDialog() {
  const user = useAtomValue(userAtom)
  const setUser = useSetAtom(setUserDataAtom)
  const m = useMessages('WelcomeDialog')
  const startWalkthrough = useStartWalkthrough()

  return (
    <Dialog
      onClose={() => setUser({showInfo: false})}
      open={user.showInfo}
      initialFocus={undefined}
      className='relative z-50'
    >
      <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
      <div className='fixed inset-0 flex sm:items-center sm:justify-center'>
        <Dialog.Panel className='mx-auto max-w-xl overflow-auto bg-white px-6 pb-[1000px] pt-8 shadow-2xl sm:rounded sm:pb-6'>
          <Dialog.Title
            className='pb-6 text-2xl focus:outline-none'
            tabIndex={0}
          >
            {m('Title')}
          </Dialog.Title>
          <Dialog.Description
            className='pb-6'
            dangerouslySetInnerHTML={{__html: m('Content')}}
          />
          <div className='flex items-stretch justify-between gap-3 sm:items-center'>
            <LocaleSelector color='primary' />
            <Button
              color='primary'
              onClick={() => {
                setUser({showInfo: false})
                startWalkthrough()
              }}
            >
              {m('NextStep')}
              <FaArrowRight />
            </Button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
