import {atom} from 'jotai'
import Cookies from 'js-cookie'

export type UserData = {
  showInfo: boolean
}

const defaultUser: UserData = {
  showInfo: process.env.NODE_ENV === 'production'
}

export const USER_COOKIE_KEY = 'taui.user'

export const userAtom = atom<UserData>(defaultUser)

export const setUserDataAtom = atom<null, [Partial<UserData>], void>(
  null,
  (get, set, updates) => {
    const current = get(userAtom)
    const newUser = {...current, ...updates}
    set(userAtom, newUser)
    if (typeof window !== 'undefined') {
      Cookies.set(USER_COOKIE_KEY, JSON.stringify(newUser))
    }
  }
)
