import {useAtomValue} from 'jotai'
import {useCallback, useEffect, useRef, useState} from 'react'

import {geocoderOptionsAtom} from '@/atoms/geocoder'
import {MapboxFeature, geocode} from '@/services/geocode'
import {GeoJsonPoi, findPois} from '@/services/poi-search'

import type {LabeledCoord} from '@/types'

const GEOCODER_DISPLAY_LIMIT = 5
const MINIMUM_CHAR_SEARCH = 4

export type GeocoderOption = LabeledCoord & {
  isPoi: boolean
}

function featureToOption(feature: MapboxFeature): GeocoderOption {
  return {
    label: feature.place_name,
    value: feature.center,
    isPoi: false
  }
}

function pointToOption(feature: GeoJsonPoi): GeocoderOption {
  return {
    label: feature.properties.name,
    value: feature.geometry.coordinates as [number, number],
    isPoi: true
  }
}

export default function useGeocoder(
  value: LabeledCoord | null,
  onChange: (value: LabeledCoord) => void
): {
  isGeocoding: number
  onBlur?: any
  onChange: (value: LabeledCoord | null) => void
  onChangeQuery: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: GeocoderOption[]
  query: string
  value: LabeledCoord | null
} {
  const geocoderOptions = useAtomValue(geocoderOptionsAtom)
  const [query, setQuery] = useState('')
  const queryRef = useRef(query)
  const [currentValue, setCurrentValue] = useState<LabeledCoord | null>(value)
  const [currentOptions, setCurrentOptions] = useState<GeocoderOption[]>([])
  const [isGeocoding, setIsGeocoding] = useState(0)

  // Keep in sync with external updates
  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  // Search on query change. Ensure that only the most recent query is used.
  useEffect(() => {
    queryRef.current = query
    if (query == null || query.length === 0) {
      setCurrentOptions([])
    } else if (query.length >= MINIMUM_CHAR_SEARCH) {
      setIsGeocoding((s) => s + 1)
      geocode(query, geocoderOptions).then(async (features) => {
        setIsGeocoding((s) => s - 1)
        if (queryRef.current === query) {
          const poiOptions = (await findPois(query)) ?? []
          const geocodedOptions = features.map(featureToOption)
          const allOptions = [
            ...poiOptions.map(pointToOption),
            ...geocodedOptions
          ]
          setCurrentOptions(allOptions.slice(0, GEOCODER_DISPLAY_LIMIT))
        }
      })
    }
  }, [geocoderOptions, query])

  const _onChange = useCallback(
    (value: LabeledCoord | null) => {
      setCurrentValue(value)
      if (value != null) onChange(value)
    },
    [onChange]
  )

  const onChangeQuery = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(e.currentTarget.value)
    },
    []
  )

  return {
    isGeocoding,
    onChange: _onChange,
    onChangeQuery,
    options: currentOptions,
    query,
    value: currentValue
  }
}
