import throttle from 'lodash/throttle'
import {atom} from 'jotai'

import {geocode, reverseGeocode} from '@/services/geocode'
import {Coord, LabeledCoord} from '@/types'

import {geocoderOptionsAtom} from './geocoder'
import {activeRouteIndexAtom} from './ui'

function setSearchParams(params: URLSearchParams) {
  if (typeof window !== 'undefined') {
    window.history.replaceState(null, '', `?${params.toString()}`)
  }
}

// History is limited to 100 updates per 30 seconds.
const throttledSetSearchParams = throttle(setSearchParams, 300, {
  trailing: true
})

function createLocationAtoms(name: string) {
  const lngLatAtom = atom<Coord | null>(null)
  const labelAtom = atom<string | null>(null)
  const locationAtom = atom<
    LabeledCoord,
    [label: LabeledCoord | string | null, value: Coord | null],
    void
  >(
    (get) => ({
      label: get(labelAtom),
      value: get(lngLatAtom)
    }),
    async (get, set, label = null, value = null) => {
      // Clear out the location to start.
      set(lngLatAtom, null)

      if (label !== null && typeof label !== 'string') {
        value = label.value
        label = label.label
      }

      const urlParams = new URLSearchParams(
        typeof window === 'undefined' ? '' : window.location.search
      )

      const setLatLng = (c: Coord | null) => {
        if (c === null) {
          urlParams.delete(name)
        } else {
          const lonStr = `${c[0]}`.slice(0, 9)
          const latStr = `${c[1]}`.slice(0, 9)
          urlParams.set(name, `${lonStr},${latStr}`)
        }
        set(lngLatAtom, c)
        throttledSetSearchParams(urlParams)
      }

      // Clear the selected routes
      set(activeRouteIndexAtom, [0, 0])

      const geocoderOptions = get(geocoderOptionsAtom)
      if (value !== null && label === null) {
        setLatLng(value)
        set(labelAtom, 'Looking up location name...')
        const features = await reverseGeocode(value, geocoderOptions)
        if (features.length > 0) {
          set(labelAtom, features[0].place_name)
        } else {
          set(labelAtom, `${value[0]}, ${value[1]}`)
        }
      } else if (label !== null && value === null) {
        set(labelAtom, label)
        const features = await geocode(label, geocoderOptions)
        if (features.length > 0) {
          const c = features[0].center
          setLatLng(c)
        } else {
          set(labelAtom, `Error geocoding: ${label}`)
          setLatLng(null)
        }
      } else {
        set(labelAtom, label)
        setLatLng(value)
      }
    }
  )
  return {
    labelAtom,
    locationAtom,
    lngLatAtom
  }
}

export const startAtoms = createLocationAtoms('start')
export const endAtoms = createLocationAtoms('end')

export const startAtom = startAtoms.locationAtom
export const endAtom = endAtoms.locationAtom

function coordToStr(c: Coord | null) {
  return c ? `${c[0]},${c[1]}` : 'null'
}

export const startEndStrAtom = atom((get) => {
  const start = get(startAtoms.lngLatAtom)
  const end = get(endAtoms.lngLatAtom)
  return `${coordToStr(start)}-to-${coordToStr(end)}`
})
