import {atom} from 'jotai'

import {configAtom} from './config'
import {scenariosBboxAtom} from './scenarios'

export const geocoderOptionsAtom = atom<
  Record<string, string | boolean | number>
>((get) => {
  const config = get(configAtom)
  const scenariosBbox = get(scenariosBboxAtom)
  const bbox = scenariosBbox[0] ?? config.geocoder.bbox

  return {
    ...config.geocoder,
    bbox: bbox ? bbox.join(',') : ''
  }
})
