import {useAtom, useSetAtom} from 'jotai'
import {usePlausible} from 'next-plausible'

import {timeOfDayIndexAtom} from '@/atoms/scenarios'
import {activeRouteIndexAtom} from '@/atoms/ui'
import useMessages, {useMessageList} from '@/hooks/use-messages'

import {Tooltip, TooltipContent, TooltipTrigger} from './tooltip'

import type {PlausibleEvents} from '@/types'

export default function AlternateTimeSelector() {
  const setActiveRouteIndex = useSetAtom(activeRouteIndexAtom)
  const [index, setIndex] = useAtom(timeOfDayIndexAtom)
  const m = useMessages('AlternateTimeSelector')
  const labels = useMessageList('AlternateTimeSelector.Values')
  const times = useMessageList('AlternateTimeSelector.Times')
  const plausible = usePlausible<PlausibleEvents>()

  const checkedClass: React.HTMLAttributes<HTMLDivElement>['className'] =
    'bg-blue-600 shadow'
  const uncheckedClass: React.HTMLAttributes<HTMLDivElement>['className'] = ''

  return (
    <div>
      <label className='font-medium text-white'>{m('Label')}</label>
      <div className='mt-1 flex w-full justify-between gap-0.5 rounded bg-slate-700 p-1 shadow-inner'>
        {labels.map((label, i) => (
          <Tooltip key={i} placement={i === 0 ? 'bottom-start' : 'bottom'}>
            <TooltipTrigger
              as='div'
              className={`cursor-pointer rounded px-2 py-1 text-xs uppercase text-white transition-all hover:bg-blue-700 hover:shadow active:bg-blue-700 active:shadow-inner ${
                i === index ? checkedClass : uncheckedClass
              }`}
              key={i}
              title={label}
              onClick={() => {
                plausible('alternateTimeSelected', {props: {index: i}})
                setActiveRouteIndex([0, 0])
                setIndex(i)
              }}
            >
              {label}
            </TooltipTrigger>
            <TooltipContent className='text-md z-50 max-w-md rounded border border-slate-900/10 bg-white px-2 py-1 shadow'>
              {times[i]}
            </TooltipContent>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}
