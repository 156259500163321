import {useAtom} from 'jotai'
import {usePlausible} from 'next-plausible'
import {startTransition, useCallback, useState} from 'react'
import Balancer from 'react-wrap-balancer'

import {timeCutoffAtom} from '@/atoms/ui'
import useMessages from '@/hooks/use-messages'

import {PlausibleEvents} from '@/types'
import useEventCallback from '@/hooks/use-event-callback'

function useTrackOnce() {
  const [tracked, setTracked] = useState(false)
  const plausible = usePlausible<PlausibleEvents>()
  return useCallback(() => {
    if (!tracked) {
      plausible('timeCutoffAdjusted')
      setTracked(true)
    }
  }, [tracked, plausible])
}

export default function TimeCutoff() {
  const [cutoff, setTimeCutoff] = useAtom(timeCutoffAtom)
  const m = useMessages()
  const trackOnce = useTrackOnce()

  const onTimeCutoffChange = useEventCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      trackOnce()
      const cutoff = parseInt(ev.currentTarget.value)
      startTransition(() => {
        setTimeCutoff(cutoff)
      })
    }
  )

  return (
    <div>
      <div className='flex items-end justify-between text-white'>
        <Balancer className='font-medium'>
          {m('TravelTimeCutoff.Label')}:
        </Balancer>
        <div className='whitespace-nowrap font-medium'>
          {`${cutoff} ${m('Units.Minutes')}`}
        </div>
      </div>
      <div className='mt-2 w-full rounded bg-slate-700 px-1 pt-1.5 leading-none shadow-inner'>
        <input
          className='w-full'
          type='range'
          min={10}
          max={120}
          onChange={onTimeCutoffChange}
          value={cutoff}
        />
      </div>
    </div>
  )
}
