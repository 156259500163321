import FlexSearch, {Index, CreateOptions} from 'flexsearch'

import poiFeatureCollection from '../../public/pois.json'

// Uncomment the following line to show POIs
export const pois: GeoJsonPoi[] = poiFeatureCollection.features as GeoJsonPoi[]

export type GeoJsonPoi = GeoJSON.Feature<
  GeoJSON.Point,
  {
    name: string
  }
>

const indexOptions: CreateOptions = {
  tokenize: 'full',
  resolution: 1
}
const poiIndex: Index<number> = new (FlexSearch as any).Index(indexOptions)

// Populate the index
for (let i = 0; i < pois.length; i++) {
  poiIndex.add(i, pois[i].properties.name)
}

export async function findPois(q: string): Promise<GeoJsonPoi[]> {
  const results = await poiIndex.search(q, {limit: 3})
  return results.map((r) => pois[r])
}
