import {atom} from 'jotai'

import {
  clientFetchCacheableResource,
  createClientCacheableUrl
} from '@/utils/client-fetch'
import createGrid from '@/utils/create-grid'

import type {DestinationConfig, ParsedGrid} from '@/types'

async function fetchGrid(url: string): Promise<ParsedGrid> {
  try {
    const res = await clientFetchCacheableResource(url)
    const buffer = await res.arrayBuffer()
    return createGrid(buffer)
  } catch (e: unknown) {
    console.error(`Error fetching grid at ${url}`)
    throw e
  }
}

export const destinationConfigsAtom = atom<DestinationConfig[]>([])

export const gridUrlsAtom = atom<string[]>((get) => {
  const configs = get(destinationConfigsAtom)
  return configs.map((c) => createClientCacheableUrl(c.url))
})

export const destinationValuesAtom = atom<Promise<ParsedGrid[]>>(
  async (get) => {
    const urls = get(gridUrlsAtom)
    return Promise.all(urls.map(fetchGrid))
  }
)
