import {atom} from 'jotai'

import type {Config} from '@/types'

// Base configuration should be updated in the `dev` branch
export const baseConfig: Config = {
  markerColors: {
    start: '#3b82f6',
    end: '#ef4444'
  },
  networkColors: [
    '#2389c9', // conveyal blue
    '#c92336', // red
    '#36c923' // green
  ],
  defaultTimeCutoff: 45,
  destinationConfigs: [],
  scenarioConfigs: [],
  poiUrl: null,
  start: null,
  end: null,
  geocoder: {
    limit: 5,
    types: 'postcode,district,place,locality,neighborhood,address,poi'
  },
  info: {
    title: 'Taui',
    text: 'Taui'
  },
  map: {
    center: [-73.995193, 40.716867],
    clickAction: 'none',
    minZoom: 9,
    style: 'mapbox://styles/conveyal/cjwu7oipd0bf41cqqv15huoim',
    zoom: 11
  },
  percentileLabels: [],
  percentileIndex: 0,
  searchPoiOnly: false,
  showAccessCard: false,
  showMapClickControl: false,
  showSelectTransitModes: true,
  showWelcomeDialog: true,
  showLink: true,
  showPercentileSelector: false,
  title: 'Taui'
}

export const configAtom = atom<Config>(baseConfig)
