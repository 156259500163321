import {RadioGroup} from '@headlessui/react'
import {useAtom} from 'jotai'
import {usePlausible} from 'next-plausible'
import dynamic from 'next/dynamic'

import {useNetworkColors} from '@/atoms/colors'
import {activeRouteIndexAtom} from '@/atoms/ui'
import useEventCallback from '@/hooks/use-event-callback'
import useMessages, {useMessageList} from '@/hooks/use-messages'

import Cell from './cell'
import RouteCardInfo from './route-card-info'
import WalkthroughStep from './walkthrough-step'

import {PlausibleEvents} from '@/types'

function LoadingRouteSegments() {
  const m = useMessages('RouteCard')
  return <RouteCardInfo>{m('SelectDestination')}</RouteCardInfo>
}

const RouteSegments = dynamic(() => import('./route-segments'), {
  ssr: false,
  loading: LoadingRouteSegments
})

function RouteCard({index, scenario}: {index: number; scenario: string}) {
  const colors = useNetworkColors()
  const m = useMessages('RouteCard')

  return (
    <div key={index} className='rounded bg-white shadow'>
      <div
        className='flex items-center rounded-t px-3 py-2'
        style={{backgroundColor: colors[index].formatHex()}}
      >
        <h2 className='font-medium text-white'>
          {m('Title', {networkName: scenario})}
        </h2>
      </div>
      <div className='flex flex-col gap-2'>
        <Cell>
          <RouteSegments key={index} index={index} />
        </Cell>
      </div>
    </div>
  )
}

export default function ScenarioCards() {
  const [activeRouteIndex, setActiveRouteIndex] = useAtom(activeRouteIndexAtom)
  const plausible = usePlausible<PlausibleEvents>()
  const scenarioNames = useMessageList('ScenarioNames')

  const onChange = useEventCallback((v: string) => {
    if (activeRouteIndex?.join(',') === v) {
      setActiveRouteIndex([0, 0])
    } else {
      const [scenarioIndex, routeIndex] = v.split(',').map((p) => parseInt(p))
      plausible('activeRouteChanged', {props: {scenarioIndex, routeIndex}})
      setActiveRouteIndex([scenarioIndex, routeIndex])
    }
  })

  return (
    <WalkthroughStep step='Routes'>
      <RadioGroup
        className='flex flex-col gap-4'
        onChange={onChange}
        value={activeRouteIndex.join(',')}
      >
        {scenarioNames.map((scenario, index) => (
          <RouteCard index={index} key={index} scenario={scenario} />
        ))}
      </RadioGroup>
    </WalkthroughStep>
  )
}
